:root {
  --color-link: #9bcb32;
  --color-link_hover: green;
  --color-text: #15171a;
  --color-background: #fff;
  --color-primary: #9bcb32;
  --color-secondary: #54595f;
  --color-accent: #1858dc;
  --color-muted: #f6f6f6;
  --color-border: #e5e9ea;
  --color-white: #fff;
  --color-red: #c61515;
  --color-pink: #d81080;
  --color-dark: #15171a;
  --color-gray: #dfe5ec;
  --color-light-gray: #f7f7f7;
  --color-hempwool: #ff9940;
  --color-hempcrete: #8ecc00;

  --font-body: Barlow, Helvetica, Arial, sans-serif;
  --font-heading: Barlow, Helvetica, Arial, sans-serif;
  --font-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;

  --height-footer: 68px;
  --height-header: 80px;
  --height-banner: 0px;

  @media (min-width: 768px) {
    --height-header: 100px;
  }
}
