.required {
  color: var(--color-red);
  padding: 0 2px;
}

// Order Form

#order-form {
  h4 {
    margin: 0;
    font-size: 18px;
    color: var(--color-text);
    font-weight: 500;
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  select,
  .btn-add {
    height: 50px;
    font-size: 16px;
    border: 1px solid var(--color-gray);
    border-radius: 40px;
    &:focus,
    &:active {
      outline: none;
      border-color: var(--color-primary);
    }
    &.error {
      border-color: var(--color-red);
    }
  }

  input::-webkit-input-placeholder {
    color: #cccccc;
  }

  select {
    font-size: 15px;
    padding: 0 20px;
    appearance: none;
    width: 100%;
  }

  input {
    appearance: none;
  }

  .react-datepicker__day--today {
    border: 1px solid;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected {
    background: var(--color-primary) !important;
  }
}

.options-selector {
  padding: 20px 30px;
  margin: auto;
  max-width: 1200px;

  h3 {
    font-weight: 700;
    font-size: 17px; // Default state
    letter-spacing: 1.3;
    text-transform: uppercase;
    color: #858585;
    margin-bottom: 30px; // Default state

    @media (min-width: 768px) {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
}

.field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  min-width: 0;
  position: relative;

  > label,
  .label {
    font-weight: 600;
    font-size: 14px;
    color: #717171;
    padding-left: 30px;
    padding-bottom: 10px;
  }
}

.form-error {
  color: var(--color-red);
  padding: 10px 30px;
  font-size: 12px;
  position: absolute;
  line-height: 1.33;
  right: 0;
}

.down-caret {
  height: 8px;
  position: absolute;
  right: 17px; // Default state
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 768px) {
    right: 25px;
  }
}

.custom-select {
  background: #fff;
  border-radius: 40px;
  overflow: hidden;
  z-index: 1;
  padding: 0;
  position: relative;
  select {
    z-index: 1;
    background: none;
    cursor: pointer;
  }

  .down-caret {
    right: 25px;
    z-index: -1;
  }
}
