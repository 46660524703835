.root {
  padding: 20px;

  > div {
    background: #fff;
    width: 80%;
    border-radius: 3px;
    box-shadow: 1px 1px 21px #769245;
    padding: 30px 0;
  }

  @media (min-width: 768px) {
    padding: 50px;

    > div {
      width: 500px;
    }
  }
}

.title {
  margin-bottom: 30px;

  h3 {
    font-size: 28px;
    margin: 0;
  }

  svg {
    height: 35px;
    margin-right: 15px;
    stroke-width: 8;
    stroke: #000;
  }
}

.message {
  padding: 30px;
  background: var(--color-white);
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
}

.instructions {
  padding: 30px;
}

.btn_wrapper {
  text-align: center;
}

.btn_reload {
  border: none;
  background: var(--color-primary);
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 3px;
  transition: all ease 0.3s;

  &:hover {
    background: #000;
  }
}
