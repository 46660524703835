* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font-body);
  color: var(--color-text);
  background-color: var(--color-background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
}

code,
pre {
  font-family: var(--font-monospace);
}

b,
strong,
.bold {
  font-weight: 700;
}

button {
  cursor: pointer;
  font-family: var(--font-body);

  &:disabled {
    pointer-events: none;
  }
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

select,
input {
  font-family: var(--font-body);
}

main {
  position: relative;
  height: initial;
  width: 100vw;
  overflow: initial;
  z-index: 0;

  @media (min-width: 768px) {
    height: 100vh;
    overflow: hidden;
  }
}

// Buttons
.btn {
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  border-radius: 50px;
  border: none;
  padding: 16px;
  width: 100%;
  outline: none;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04);
  transition: all ease 0.3s;
}

.btn-naked {
  border: none;
  background: none;
  outline: none;
}

.btn-next {
  margin-top: 20px;
  margin-bottom: 50px;
}

.btn-next {
  background: #a7a7a7;

  &.active {
    background: var(--color-primary);

    &:hover {
      transform: translateY(-5px);
    }
  }
}

// Stripe

#card-element {
  padding: 20px 30px 40px;
  border: 1px solid #f0f0f0;
  height: 50px;
  background: #fbfcfc;
}
