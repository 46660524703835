/* Form Parts */
.step-1 {
  transition: ease 0.3s;
}

.fade {
  opacity: 0;
  transition: ease 0.3s;
}

.fade.enter-done,
.step-1 {
  opacity: 1;
  transform: translateY(0px);
}

.fade.exit,
.step-1.exit {
  transform: translateY(20px);
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

/* Title Animation */

.form-content {
  transition: all ease 0.5s 0.2s;
}

.form-content.hide-title {
  margin-top: -140px;
}

.app-title-exit,
.app-title-exit-done {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all ease 0.3s;
}

// Enter transition classes
.app-title-enter,
.app-title-enter-done {
  opacity: 1;
  visibility: visible;
}
