/* Utility Classes */
.bold {
  font-weight: 700;
}

.caps {
  text-transform: uppercase;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.cover {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}
